@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.login-page {
  align-content: center;
  display: grid;
  gap: 60px;
  height: 100vh;
  justify-content: center;
  margin: 0;
  padding: 0;
  width: 100vw;
}
.login-page__logo-wrap {
  display: grid;
  gap: 12px;
  justify-items: center;
}

.login-page__logo {
  display: grid;
  gap: 4px;
  justify-items: center;
}

.login-page__title {
  @include textSemiBold-20();
  line-height: 24px;
}

.login-page__subtitle {
  @include textRegular-12();
  line-height: 16px;
}

.login-page__form {
  display: grid;
  gap: 20px;
  width: 400px;
}

.login-page__button {
  text-transform: none;
}

.login-page__info {
  background-color: $color-black-5;
  border-radius: 2px;
  display: grid;
  padding: 8px;
}

.login-page__info-row {
  @include textRegular-14-black-50();
  line-height: 20px;
  text-align: center;
  text-transform: none;
}

.login-page__info-row span {
  @extend .login-page__info-row;
  @include textSemiBold-14-black-50();
}

.login-page__info_error {
  @extend .login-page__info-row;
  color: $color-text-red;
}

.login-page__info_error .login-page__info-row {
  color: $color-text-red;
}

.login-page__button.button_disabled {
  @include textSemiBold-14-disabled();
  background-color: $color-gray-alfa;
}

.login-page__code-inputs {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(4, 47px);
  justify-content: center;
}

.login-page__code-inputs .input_outlined:not(.input_invalid) {
  border-color: transparent;
  box-shadow: 0 0 0 2px $color-input-outline;
}

.login-page__code-inputs .input__field {
  @include textSemiBold-16();
  line-height: 20px;
  text-align: center;
}

.login-page__code-inputs .input__placeholder {
  @include textSemiBold-16-light();
  left: 19px;
}

.login-page__code-actions {
  display: grid;
  gap: 16px;
  grid-template-columns: max-content 1px max-content;
  justify-content: center;
}

.login-page__code-actions .button_link {
  @include textSemiBold-14-ghost();
  letter-spacing: 0.03em;
  line-height: 20px;
  text-transform: none;
}

.login-page__code-actions .button_link.button_disabled {
  color: $color-black-10;
}

.login-page__code-actions-divider {
  align-items: center;
  background: $color-black-10;
  height: 16px;
  transform: translateY(2px);
  width: 1px;
}
