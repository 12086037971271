@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.info-panel {
  align-items: center;
  border-top: 1px solid $color-border-gray;
  display: grid;
  grid-auto-flow: column;
  grid-row: 2 / 3;
  justify-content: space-between;
  padding: 12px 20px;
}

.info-panel__list {
  display: grid;
  gap: 10px;
  grid-auto-flow: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.info-panel__list-item {
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
  justify-content: start;
  margin: 0;
}

.info-panel__label {
  @include textRegular-14-caption();
  line-height: 20px;
  white-space: nowrap;
}

.info-panel__value {
  @include textSemiBold-14();
  line-height: 20px;
  min-width: 24px;
}

.info-panel__list-item_red {
  .info-panel__label,
  .info-panel__value {
    color: $color-text-red;
  }
}

.info-panel__button {
  min-width: 315px;
}
