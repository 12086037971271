@import 'src/scss/variables';

.sorting-center-page {
  display: grid;
  grid-template-columns: $filter-panel-width 1fr;
  grid-template-rows: 1fr $info-panel-height;
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.sorting-center-page_day-closed {
  grid-template-rows: 1fr max-content;
}
