@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.list-documents-table {
  padding: 20px 19px;
}

.list-documents-table__header {
  background: $color-variant-light;
  border-radius: 4px;
  padding: 0 24px;
}

.list-documents-table__body {
  border: 1px solid $color-gray-beta;
  border-radius: 4px;
  padding: 0 24px;
}

.list-documents-table__cell {
  align-items: center;
  display: grid;
  padding: 12px 28px 12px 0;
}

.list-documents-table__icon {
  color: $color-theme-light;
  transition: color $transition-time;
}

.list-documents-table__icon_hover:hover {
  color: $color-button-hover;
}

.list-documents-table__brigade-wrapper {
  align-items: center;
  display: grid;
  gap: 12px;
  grid-auto-flow: column;
  justify-content: start;

  .list-documents-table__choose-button {
    width: 44px;
  }
}

.list-documents-table__brigade {
  @include textSemiBold-14();
  line-height: 20px;
  overflow: hidden;
  overflow-wrap: break-word;
}

.list-documents-table__brigade_not-selected {
  color: $color-red;
}
