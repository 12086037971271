@import 'src/scss/variables';
@import 'src/scss/mixins/text';

.input {
  align-items: center;
  border: 1px solid $color-border-basic;
  border-radius: 4px;
  box-sizing: border-box;
  display: grid;
  height: 36px;
  padding: 0 12px;
  position: relative;
  transition: border-color $transition-time ease-in-out;
  width: 100%;
}

.input__field {
  @include textRegular-14();
  border: none;
  box-sizing: border-box;
  height: 20px;
  outline: none;
  padding: 0;
  width: 100%;
}

.input:hover {
  border-color: $color-border-dark;
}

.input:not(.input_disabled):focus-within {
  border-color: transparent;
  box-shadow: 0 0 0 2px $color-input-outline;
}

.input_invalid {
  border-color: $color-red;

  .input__field {
    color: $color-red;
  }
}

.input_invalid:hover {
  border-color: $color-red;
}

.input_disabled {
  background: $color-black-5;
  border-color: $color-black-5;
}

.input_disabled:hover {
  border-color: $color-black-5;
}

.input_disabled .input__field,
.input_disabled .input__value,
.input_disabled .input__placeholder {
  background: $color-black-5;
  color: $color-black-20;
}

.input_icon {
  gap: 8px;
  grid-template-columns: 1fr max-content;
}

.input_multi {
  height: auto;
  padding: 0;
}

.input_multi textarea {
  @include textRegular-14();
  border: none;
  line-height: 20px;
  outline: none;
  padding: 12px;
  resize: vertical;
}

.input_search {
  width: 280px;

  .input__field {
    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  .input__icon {
    color: $color-text-light;
  }
}

.input_invalid .input__field {
  color: $color-red;
}
